<template>
  <div class="create-content-main">
    <div class="create-slag-form background-content-admin">
      <div class="d-flex w-100 mx-2 cpx-4">
        <div class="cpy-2 col-6 px-0">
          <span class="w-100 font-title font-weight-bold">決済情報</span>
        </div>
        <div class="p-3 col-6">
          <button
            v-on:click="returnGlobal()"
            class="float-right button-back-dashboard back-btn"
          >
            戻る
          </button>
        </div>
      </div>
      <form class="" id="create-content" autocomplete="off">
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >決済情報（Paypal）ID【本番】</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="paypal_id"
              placeholder="paypalキーの入力"
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >決済情報（Paypal）Secret【本番】</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="paypal_secret"
              placeholder="paypalシークレットキーの入力"
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >決済情報（Stripe）ID【本番】</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="stripe_id"
              placeholder="Stripeキーの入力"
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold cp-label"
            >決済情報（Stripe）Secret【本番】</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="stripe_secret"
              placeholder="Stripeシークレットキーの入力"
            />
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-center mt-4 py-4">
        <b-button
          v-on:click.prevent="CreatePaymentSetting"
          :disabled="isLoading"
          class="mr-4 btn-color"
          variant="success"
        >
          <b-spinner v-if="isLoading" small></b-spinner>
          保存
        </b-button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
// import { FeedBack } from "@/utils/feedback.js";
// import { Api } from "../../utils/http-common.js";
// import { Urls } from "../../utils/urls";
// import { Constants } from "../../utils/constants.js";

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});

export default {
  name: "createFixedPage",
  components: {
    // VueEditor,
  },
  data() {
    return {
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      paypal_id: "",
      paypal_secret: "",
      stripe_id: "",
      stripe_secret: "",
      isLoading: false,
    };
  },
  created() {
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
    this.getShopByIdForAdmin(this.shop_id);
  },
  computed: {
    ...mapGetters(["success", "message", "error", "detailShop"]),
  },
  watch: {
    detailShop() {
      this.paypal_id = this.detailShop.paypal_id;
      this.paypal_secret = this.detailShop.paypal_secret;
      this.stripe_id = this.detailShop.stripe_id;
      this.stripe_secret = this.detailShop.stripe_secret;
    },
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.isLoading = false;
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
  },
  methods: {
    ...mapActions({ getShopByIdForAdmin: "getShopByIdForAdmin" }),
    ...mapActions({ CreatePaymentSetting: "CreatePaymentSetting" }),
    async CreatePaymentSetting() {
      this.isLoading = true;
      let shop_id = this.shop_id;
      const request = {
        shop_id: shop_id,
        paypal_id: this.paypal_id,
        paypal_secret: this.paypal_secret,
        stripe_id: this.stripe_id,
        stripe_secret: this.stripe_secret,
      };
      const dataReturn = await this.$store.dispatch(
        "CreatePaymentSetting",
        request
      );
      if (dataReturn.success) {
        if (this.$route.params.shopId) {
          this.$router.push({
            name: "DashboardAdminSetting",
            params: { shopId: this.shop_id },
          });
        } else {
          this.$router.push({
            name: "DashboardAdminSetting domain",
          });
        }
      } else {
        this.isLoading = false;
      }
    },
    returnGlobal() {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "DashboardAdminSetting",
          params: { shopId: this.shop_id },
        });
      } else {
        this.$router.push({
          name: "DashboardAdminSetting domain",
        });
      }
    },
  },
};
</script>
<style lang="scss">
.ql-container {
  border: none !important;
  .ql-editor {
    background-color: white;
    height: auto;
  }
}
.error-input {
  border: solid 1px #e55353 !important;
}
.message-error {
  color: #e55353;
}
</style>
<style scoped>
.background-content-admin {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  padding: 0px !important;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.cp-label {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
.row-input input {
  margin: 0px !important;
}
.create-content {
  padding: 0px !important;
}
</style>
